import React from "react";
import Layout from "components/layout";
import SEO from "components/seo";

const Contact: React.FC = () => {
  return (
    <>
      <SEO title="Contact" />
      <Layout>
        <div className="container prose prose-list dark:prose-dark mx-auto p-6">
          <h1 className="font-serif">Contact</h1>
          <p>Get in touch if you need help with building digital products.</p>
          <ul>
            <li>
              Email:{" "}
              <a href="mailto:hi.web@oleggera.com">hi.web@oleggera.com</a>
            </li>
            <li>
              Twitter: <a href="https://twitter.com/gerlv">@gerlv</a>
            </li>
          </ul>
          <h2 className="font-serif">How I can help</h2>
          <ul>
            <li>Building digital products</li>
            <li>Technical direction</li>
            <li>Consultancy</li>
            <li>Brainstorming</li>
            <li>Creating / reviewing technical requirements</li>
            <li>Reviewing technical proposals</li>
            <li>Interviewing technical candidates</li>
            <li>Implementing software engineering best practices</li>
          </ul>
        </div>
      </Layout>
    </>
  );
};

export default Contact;
